.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #002c5d;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  min-height: 10vh;
  background-color: #002c5d;
  color: white;
  font-size: 0.9em;
  padding: 1em 0;
  width: 100%;
}
